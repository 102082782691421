const LINKS = {
  404: '/404/',
  500: '/500/',
  ALIEXPRESS_BIG_DEALS: '/promocoes/selecao/melhores_da_ali/',
  ALIEXPRESS_FIRST_PURCHASE_DEALS:
    'https://s.click.aliexpress.com/deep_link.htm?aff_short_key=_DlGjKW7&dl_target_url=https%3A%2F%2Fpt.aliexpress.com%2F',
  APP_DOWNLOAD: 'https://promobit.onelink.me/OPOZ/g38ldz5y',
  BADGES_AND_LEVELS: `https://${process.env.NEXT_PUBLIC_SUBDOMAIN}.promobit.com.br/profile/medalhas-e-niveis/`,
  BLACK_FRIDAY: '/black-friday/',
  BLOG: '/blog/',
  BUSINESS: `/comercial/parcerias/`,
  COMMUNITY_GUIDELINES: '/institucional/diretrizes-da-comunidade/',
  COOKIES_POLICY: `/institucional/cookies/`,
  COUPONS: '/cupons/',
  DELETE_ACCOUNT: '/configuracoes/exclusao-de-conta/',
  FAQ: `/institucional/faq/`,
  FORUM: '/forum/',
  HOME: '/',
  IDINHEIRO: 'https://www.idinheiro.com.br/',
  LANDING_APPS: `https://${process.env.NEXT_PUBLIC_SUBDOMAIN}.promobit.com.br/ofertas/app-ofertas/`,
  LAST_COUPONS: '/cupons/ultimos-cupons/',
  MELHOR_PLANO: 'https://melhorplano.net/',
  MELIUZ: 'https://www.meliuz.com.br/',
  MESSAGES: `https://${process.env.NEXT_PUBLIC_SUBDOMAIN}.promobit.com.br/central/mensagens/`,
  MINHA_CONEXAO: 'https://www.minhaconexao.com.br/',
  MY_OFFERS: '/minhas-ofertas/',
  NEW_COUPON: `/cupons/novo-cupom/`,
  NEW_OFFER: `/promocoes/nova-oferta/`,
  NEW_THREAD: `/forum/novo-topico/`,
  NOTIFICATIONS: '/notificacoes/',
  MUAMBATOR: 'https://www.muambator.com.br/',
  PROMOBIT_COUPONS: '/cupons/promobit/',
  PROMOBIT_WHAT_IS: `https://${process.env.NEXT_PUBLIC_SUBDOMAIN}.promobit.com.br/o-que-e-promobit/`,
  PURCHASE_ISSUES: `/institucional/compra-segura/`,
  SEARCH: '/buscar/',
  SITEMAP: '/mapa-site/',
  STORES: '/lojas/',
  SETTINGS: '/configuracoes/',
  RECENT_OFFERS: '/promocoes/recentes/',
  TREND_OFFERS: '/promocoes/em-alta/',
  LOW_PRICE_OFFERS: '/promocoes/menor-preco/',
  WISHLIST: '/lista-de-desejos/',
};

export default LINKS;
